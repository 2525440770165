body {
	margin: 0;
	font-family: Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
	font-size: 0.8125rem;
	font-weight: 400;
	line-height: 1.5;
	color: #5a5c69;
	text-align: left;
	background-color: #fff;
	-webkit-font-smoothing: antialiased;
	text-rendering: geometricPrecision;
	-webkit-font-smoothing: antialiased;
}

.record-container {
	padding-top: 30px;
}

.header-nav {
	margin-left: 5px;
	margin-right: 10px;
}

.pl-0,
.px-0 {
	padding-left: 0 !important;
}

.pr-0,
.px-0 {
	padding-right: 0 !important;
}

.pt-0,
.py-0 {
	padding-top: 0 !important;
}

.d-flex {
	display: flex !important;
}

.mb-2,
.my-2 {
	margin-bottom: 0.5rem !important;
}

.flex-grow-1 {
	flex-grow: 1 !important;
}

.mb-0 {
	margin-bottom: 0 !important;
}

.text-right {
	text-align: right !important;
}

.mb-2 {
	margin-bottom: 0.5rem !important;
}

.mt-1 {
	margin-top: 0.25rem !important;
}

.flex-grow-1 {
	flex-grow: 1 !important;
}

.justify-content-end {
	justify-content: flex-end !important;
}

.d-flex {
	display: flex !important;
}

.h4 {
	font-size: 1.21875rem;
	margin: 0;
	font-family: inherit;
	font-weight: 700;
	line-height: 1.2;
}

h2 {
	display: block;
	margin-block-start: 0.83em;
	margin-block-end: 0.83em;
	margin-inline-start: 0px;
	margin-inline-end: 0px;
	unicode-bidi: isolate;
}

.pt-2 {
	padding-top: 0.5rem !important;
}

.small {
	font-size: 70%;
	font-weight: 400;
}

.table thead tr {
	background-color: #e9fdff;
	color: #075da8;
}

.btn:not(:disabled):not(.disabled) {
	cursor: pointer;
}

.ml-2 {
	margin-left: 0.5rem !important;
}

.btn-sm,
.btn-group-sm > .btn {
	padding: 0.25rem 0.5rem;
	font-size: 0.7109375rem;
	line-height: 1.5;
	border-radius: 0.2rem;
}

.btn-outline-secondary {
	color: #858796 !important;
	border-color: #d8dbf0 !important;
	background-color: #fff !important;
	border: 1px solid #cccccc !important;
	display: inline-block;
	width: 38px !important;
	height: 38px !important;
}

.pad-right20 {
	margin-right: 35px;
}

.btn-outline-secondary:hover {
	color: #fff !important;
	background-color: #858796 !important;
	border-color: #858796 !important;
}

.btn {
	display: inline-block;
	font-weight: 400;
	text-align: center;
	vertical-align: middle;
	user-select: none;
	background-color: transparent;
	border: 1px solid transparent;
	transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.btn-primary {
	color: #fff;
	background-color: #075da8;
	border-color: #075da8;
	padding: 8px 12px !important;
	font-weight: 400 !important;
	border-radius: 4px !important;
}

.btn-secondary {
	color: #fff;
	background-color: #858796 !important;
	border-color: #858796 !important;
	padding: 8px 12px !important;
	font-weight: 400 !important;
	border-radius: 4px !important;
}
.rounded-circle {
	border-radius: 100px !important;
}

.ml-10 {
	margin-left: 10px;
}
.p-0 {
	padding: 0px !important;
}

.m-0 {
	margin: 0px !important;
}

//table css
.grid-table-container {
	max-height: calc(100vh - 225px);
}

.custom-control-inline {
	display: inline-flex;
	margin-right: 1rem;
}

.pl-3 {
	padding-left: 15px;
}

.border_table {
	border: 1px solid #dddfeb;
	border-top: 0px;
	border-bottom: 0px;
}

.grid-table-container {
	position: relative;
	min-height: 300px;
	max-height: calc(100vh - 196px);
	border-left: 1px solid #dddfeb;
}

.table-responsive {
	display: block;
	width: 100%;
	overflow-x: auto;
	-webkit-overflow-scrolling: touch;
	-ms-overflow-style: -ms-autohiding-scrollbar;
}

.table-responsive > .table-bordered {
	border: 0;
}

// .table-bordered {
//   border: 1px solid #dddfeb;
// }
.table {
	width: 100%;
	background-color: transparent;
}

table {
	border-collapse: collapse;
}

table {
	display: table;
	// border-collapse: separate;
	// box-sizing: border-box;
	text-indent: initial;
	unicode-bidi: isolate;
	border-spacing: 2px;
	// border-color: gray;
}

.grid-table-container thead {
	position: sticky;
	top: 0px;
	background-color: #fff;
	z-index: 4;
}

thead {
	display: table-header-group;
	vertical-align: middle;
	unicode-bidi: isolate;
	border-color: inherit;
}

table {
	text-indent: initial;
	border-spacing: 2px;
}

tr {
	display: table-row;
	vertical-align: inherit;
	unicode-bidi: isolate;
	border-color: inherit;
}

.table .head-row {
	background-color: #e9fdff;
	color: #075da8;
}

.head-row {
	background: #eaecf4;
	line-height: 25px;
}

.table th .resizer {
	display: inline-block;
	width: 5px;
	height: 100%;
	position: absolute;
	right: 0;
	top: 0;
	transform: translateX(50%);
	z-index: 1;
}

.resizer {
	display: inline-block;
	width: 5px;
	height: 100%;
	position: absolute;
	right: 0;
	top: 0;
	transform: translateX(50%);
	z-index: 1;
	touch-action: none;
	/* Disable touch event handling */
}

.resizer.isResizing {
	background: #e9fdff;
}

.table {
	width: 100%;
	border-collapse: collapse;
}

i {
	cursor: pointer;
}

.closeicon {
	color: #82add3;
}

.eyeicon i {
	color: #858796;
	align-content: center;
	margin-left: 10px;
	font-size: smaller;
}

.grid-table-container th,
.grid-table-container td {
	border: 1px solid #dddfeb;
	padding: 7px;
	position: relative;
	width: 150px;
}

.inputtext {
	color: rgb(58, 59, 69) !important;
	border: 1px solid rgb(209, 211, 226) !important;
	// min-width: 20px!important;
	// width: 120.719px!important;
	// min-width: none;
	width: 100%;
	padding-left: 6px;
	line-height: 25px;
	border-radius: 3px;
	margin-top: 5px;
	box-sizing: "border-box";
}

.inputtextd1 {
	color: rgb(58, 59, 69) !important;
	border: 1px solid rgb(209, 211, 226) !important;
	// min-width: 20px!important;
	// width: 120.719px!important;
	// min-width: none;
	width: 100%;
	padding-left: 6px;
	line-height: 25px;
	border-radius: 3px;
	margin-top: 5px;
	box-sizing: border-box !important;
	min-width: 77px;
}

.css-13cymwt-control {
	-webkit-align-items: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	cursor: default;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-flex-wrap: wrap;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	justify-content: space-between;
	min-height: 38px;
	outline: 0 !important;
	position: relative;
	-webkit-transition: all 100ms;
	transition: all 100ms;
	background-color: hsl(0, 0%, 100%);
	border: 1px solid rgb(209, 211, 226) !important;
	border-radius: 3px;
	box-sizing: border-box;
}

.head-row th.grid-col-freeze {
	position: sticky !important;
	right: 0;
	background-color: #e9fdff;
}

.border_table {
	display: block !important;
	width: 100% !important;
	overflow-x: scroll !important;
}

.grid-table-container {
	max-height: calc(100vh - 225px);
}

.svc-tab-designer {
	display: flex;
	flex-grow: 1;
	height: 100%;
	overflow-x: hidden !important;
	position: static;
}

.per-page select {
	border: 1px solid rgb(209, 211, 226) !important;
	border-radius: 3px;
	padding: 5px 8px;
}

.modal-overlay {
	z-index: 5;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.5);
	display: flex;
	justify-content: center;
	align-items: center;
}

.modal {
	background: white;
	border-radius: 5px;
	width: 100%;
	max-width: 530px;
	box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

.modal-dialog {
	position: absolute;
	width: auto;
	margin: 0.5rem;
	pointer-events: none;
	top: 60px !important;
}

.modal {
	position: absolute;
	top: 40px !important;
	height: 430px;
}

.modal-content {
	position: relative;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-direction: column;
	flex-direction: column;
	width: 100%;
	pointer-events: auto;
	background-color: #fff;
	background-clip: padding-box;
	border: 1px solid rgba(0, 0, 0, 0.2) !important;
	border-radius: 0.3rem;
	outline: 0;
}

.border {
	border: 1px solid #efefef;
}

.modal-body .exportcs {
	padding-top: 10px;
	width: 514px;
	padding: 10px 3px 10px 10px;
}

.close-button i {
	padding-right: 15px;
	padding-top: 15px;
}

.h5records {
	padding: 10px 10px 0px 10px;
}

.modal-body .maindv {
	padding-top: 10px;
	overflow-x: scroll !important;
	height: 300px;
	width: 514px;
	padding: 10px 3px 10px 10px;
}

.modal-header {
	border-radius: 15px;
}

.modal-close {
	float: right;
}

.close-button {
	float: right;
}

h5 {
	font-size: 1.015625rem;
	padding: 0px;
	margin: 0px;
	// color: #075da8;
	font-weight: 500;
}

.modal-content {
	border: 0 !important;
}

.shadow {
	box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1) !important;
}

.modal-header .close {
	padding: 1rem 1rem;
	margin: -1rem -1rem -1rem auto;
}

.hr-width {
	margin-top: 10px;
}

.btn-close {
	background: #717384 !important;
	color: #fff !important;
	border-color: #717384 !important;
	padding: 8px 12px !important;
	font-weight: 400 !important;
	border-radius: 5px !important;
}
.btn-secondary1:hover {
	color: #fff;
	background-color: hsl(211, 100%, 50%);
	border-color: #07284b !important;
	padding: 8px 12px !important;
	font-weight: 400 !important;
	border-radius: 5px !important;
}
.btn-secondary1 {
	color: #007bff !important;
	background-color: #fff !important;
	border-color: #07284b !important;
	padding: 8px 12px !important;
	font-weight: 400 !important;
	border-radius: 5px !important;
	border: 1px solid #007bff !important;
}

.grid-settings-table {
	width: 500px !important;
}

.grid-settings-table .hrow {
	background-color: #e9fdff !important;
	color: #075da8 !important;
}

.grid-settings-table th,
.grid-settings-table td {
	border: 1px solid #dddfeb;
	padding: 7px;
	position: relative;
}

.modal-footer {
	text-align: right;
	padding: 15px;
}

.modal-close i {
	padding-right: 15px;
	padding-top: 15px;
}

.modal-title {
	padding: 10px 10px 0px 10px;
}

.pagination-controls button.active {
	background: #075da8 !important;
	color: #fff !important;
	border-color: #075da8 !important;
}

.page-link {
	position: relative;
	display: block;
	padding: 0.2rem 0.75rem;
	margin-left: -1px;
	line-height: 1.25;
	color: #075da8;
	background-color: #fff;
	border: 1px solid #dddfeb;
}

.table-responsive tr th:last-child {
	position: sticky !important;
	// margin-right: 27px;
	background-color: #e9fdff;
	position: sticky !important;
	right: 0;
}

.table-bordered td:last-child {
	position: sticky !important;
	// margin-right: 27px;
	// background-color: #e9fdff;
	background-color: #fff;
	position: sticky !important;
	right: 0;
}

.setting-table-wrapper {
	box-sizing: border-box;
}

.radio-label {
	color: #858796 !important;
}

.radio-label input {
	padding: 0px !important;
	margin: 0px !important;
}

.form-label {
	color: #858796 !important;
}

.loader-overlay {
	position: fixed;
	top: 45%;
	left: 55%;
	transform: translate(-50%, -50%);
	background: rgba(255, 255, 255, 0.8);
	padding: 20px;
	border-radius: 8px;
	z-index: 9999;
	align-items: center;
}

.loader-overlay img {
	display: block;
	margin: 0 auto;
	align-items: center;
}

.no-records-message {
	font-size: medium;
}

/* Add these styles in your CSS or within a style tag in your component */
// .cell-with-ellipsis {
//   white-space: nowrap;
//   overflow: hidden;
//   text-overflow: ellipsis;
//   max-width: 150px; /* Adjust as needed */
// }

.input-with-ellipsis {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	width: 100%;
	/* Adjust as needed */
}

.tooltip-container {
	position: relative;
	display: inline-block;
}

.header-name {
	line-height: 10px;
}

.tooltip-text {
	visibility: hidden;
	background-color: #000;
	/* Tooltip background color */
	color: #fff;
	/* Tooltip text color */
	text-align: center;
	border-radius: 3px;
	padding: 4px;
	position: absolute;
	z-index: 1;
	top: -50%;
	right: 10%;
	/* Position to the left of the button */
	transform: translateY(-50%);
	opacity: 0;
	transition: opacity 0.3s;
	white-space: nowrap;
	/* Prevent tooltip text from wrapping */
}

.tooltip-text::after {
	content: "";
	position: absolute;
	top: 100%;
	left: 50%;
	/* Position arrow at the right side of the tooltip */
	margin-top: -5px;
	border-width: 5px;
	border-style: solid;
	border-color: transparent transparent transparent #000;
	/* Arrow color matching tooltip background */
}

.tooltip-container:hover .tooltip-text {
	visibility: visible;
	opacity: 1;
}

.search-input {
	line-height: 10px;
}

.pagination-container {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-top: 2px;
	margin-left: 5px;
	margin-right: 18px;
}
.pagination-controls button {
	margin: 0 2px;
	padding: 5px 10px;
	border: 1px solid #ccc;
	background: #f9f9f9;
	cursor: pointer;
}
.pagination-controls button.active {
	background: #007bff;
	color: white;
	border-color: #007bff;
}
.pagination-controls button:disabled {
	cursor: not-allowed;
	opacity: 0.5;
}
.pagination-controls span {
	margin: 0 10px;
}
.per-page {
	display: flex;
	align-items: center;
}
.myIcon {
	margin-left: 10px;
	// text-align: right;
	// float: right;
	position: absolute;
	right: 10px;
	color: #77cadc;
	font-size: 1rem;
}

// EditRecord css
.text-lg {
	font-size: 1.2rem;
}

.font-weight-bold {
	font-weight: 700 !important;
}
.mt-4 {
	margin-top: 1.5rem !important;
	font-size: 0.8125rem;
}

h6 {
	margin-bottom: 0.5rem;
	font-family: inherit;
	line-height: 1.2;
}

.form-control {
	transition: none;
}

.form-control {
	display: block;
	width: 100% !important;
	height: calc(1.46875rem + 0px);
	padding: 0.375rem 0.75rem;
	font-size: 0.8125rem;
	font-weight: 400;
	line-height: 1.5;
	color: #3a3b45;
	background-color: #fff;
	background-clip: padding-box;
	border: 1px solid #d1d3e2;
	border-radius: 0.35rem;
}
.phone-form-control {
	display: block;
	width: 95%;
	height: calc(1.5rem + 0px);
	padding: 0.375rem 0.75rem;
	font-size: 0.8125rem;
	font-weight: 400;
	line-height: 1.5;
	color: #3a3b45;
	background-color: #fff;
	background-clip: padding-box;
	border: 1px solid #d1d3e2;
	border-radius: 0.35rem;
}

.phoneformcontrol {
	display: block;
	width: 505px !important;
	height: calc(1.9rem + 8px);
	padding: 0.375rem 0.75rem;
	font-size: 0.8125rem;
	font-weight: 400;
	line-height: 1.5;
	color: #3a3b45;
	background-color: #fff;
	background-clip: padding-box;
	border: 1px solid #d1d3e2;
	border-radius: 0.35rem;
}

p {
	margin-top: 0;
	margin-bottom: 1rem;
}

.field-error {
	font-size: 11px;
}

.dateformcontrol {
	display: block;
	width: 479px !important;
	height: calc(1.5rem + 0px);
	padding: 0.375rem 0.75rem;
	font-size: 0.8125rem;
	font-weight: 400;
	line-height: 1.5;
	color: #3a3b45;
	background-color: #fff;
	background-clip: padding-box;
	border: 1px solid #d1d3e2;
	border-radius: 0.35rem;
}

.col {
	-ms-flex-preferred-size: 0;
	flex-basis: 0;
	-ms-flex-positive: 1;
	flex-grow: 1;
	max-width: 100%;
}

.disabled-row {
	// opacity: 0.5; /* This will visually grey out the row */
	pointer-events: none; /* This will prevent any interaction */
}

.deleteBtn button.active {
	background: #075da8 !important;
	color: #fff !important;
	border-color: #075da8 !important;
}

.deleteBtn button.active {
	background: #007bff;
	color: white;
	border-color: #007bff;
}
.deleteBtn button:disabled {
	cursor: not-allowed;
	opacity: 0.5;
}

.header-name {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	padding: 8px 12px; /* Adjust padding for better spacing */
}

.header-content {
	display: flex;
	align-items: center; /* Ensure proper vertical alignment */
	justify-content: space-between;
	overflow: hidden;
	width: 100%;
	gap: 15px; /* Further increase space between text and sort icon */
	min-height: 24px; /* Ensure enough height for text */
}

.header-text {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	flex-grow: 1;
	min-width: 0;
	padding-right: 18px; /* Extra space between text and sort icon */
	line-height: 1.4; /* Adjust for better vertical centering */
}

.eyeicon i.fa:hover {
	color: #007ee3;
	transform: scale(1.2);
}

.rightsectionfixed {
	position: sticky;
	top: 0px;
	z-index: 8;
	background-color: #fff;
	padding-top: 1.5rem;
}

.checkbox-container {
	display: flex;
	align-items: center;
	// justify-content: center;
	height: 100%;
	width: 100%;
}

.custom-checkbox {
	appearance: none;
	border: #adb5bd solid 1px;
	border-radius: 4px;
	position: absolute;
	height: 15px;
	width: 15px;
	cursor: pointer;
	background-color: #fff;
	transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.custom-checkbox:hover {
	background-color: rgb(7, 93, 168);
}

.custom-checkbox:checked {
	background-color: rgb(7, 93, 168);
	border-color: rgb(7, 93, 168);
}

.custom-checkbox:checked::after {
	content: "✔";
	color: white;
	font-size: 8px;
	font-weight: bold;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.hover-row:hover {
	background-color: #eef6fd;
}

.hover-row:hover td:last-child {
	background-color: #eef6fd !important;
}

.grip {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	cursor: col-resize;
	width: 10px;
	display: none; /* Hidden by default */
	z-index: 99;
}

/* Show the grip on hover */
.head-row.title th:hover .grip {
	display: block;
	border-right: solid 5px #040f18;
}

.loading {
	position: fixed; /* Ensures it covers the whole viewport */
	background: rgba(255, 255, 255, 0.8);
	left: 0;
	top: 0;
	width: 100vw; /* Full viewport width */
	height: 100vh; /* Full viewport height */
	z-index: 1010999;
	display: flex;
	align-items: center;
	justify-content: center;
}

.loading img {
	position: relative;
	transform: translate(-50%, -50%);
}
