.email-blocks {
    display: flex;
    margin-top: 1rem;

    .icon-block {
        --border-color:#eee;
        border: 1px solid var( --border-color, #f7f7f7);
        height: 110px;
        width: 160px;
        margin-right: 1rem;
        padding: 30px;
        text-align: center;
        cursor: pointer;
        border-radius: .5rem;
        transition: all .3s;

        &:hover {
            background-color:var(--primary);
            color:white;
            border-color: var(--primary);
            i{
                color:white;
            }
        }

        i {
            font-size: 70px;
            color: #075da8;
        }
    }


}

.settings-back {
    cursor: pointer;
}

.subject-content {
    overflow: auto;
    overflow-wrap: break-word !important;
    white-space: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    cursor: text;
}

.subject-content:empty::before {
    content: "Enter email subject line...";
    color: #ccc;
}

// .settings-form iframe {
//     width: 100%;
//     height: 450px;
// }
.EmailTemplatePreview {
    height: calc(100vh - 45px);
}

.form-cb-group2 label{
    line-height: calc(3*(var(--sjs-base-unit, var(--base-unit, 8px))));
    font-size: calc(2*(var(--sjs-base-unit, var(--base-unit, 8px))));
    font-family: var(--sjs-font-family, var(--font-family));
    color: var(--sjs-general-forecolor-light, var(--foreground-light, #000));
    font-weight: 600;
    margin-bottom: 0.35rem;
    font-size: 14px;
}